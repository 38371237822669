import React, { Component } from 'react';
import { ReactComponent as AngularLogo } from './Icons/angular.svg';
import { ReactComponent as JavaScriptLogo } from './Icons/javascript.svg';
import { ReactComponent as ReactLogo } from './Icons/react.svg';
import { ReactComponent as ReduxLogo } from './Icons/redux.svg';
import { ReactComponent as SassLogo } from './Icons/sass.svg';
import { ReactComponent as TypeScriptLogo } from './Icons/typescript.svg';

export default class Profile extends Component {
  currentDescriptionLineTyping = 0;
  currentDescriptionCharacterTyping = 0;

  profileDescription = [
    'Senior Frontend Developer, Bachelor of Science,',
    'pianist, guitarist, Wainwrighter, undisputed',
    'Copter champion, (terrible) singer, below',
    'average pool player, adventurer, runner,',
    'runner, minimalist, wild camper,',
    `David O'Doherty owes me 50p.`
  ];

  constructor() {
    super();
    this.state = { showingProfileDescription: ['', '', '', '', '', ''] };
  }

  renderDescriptionLines() {
    return this.state.showingProfileDescription.map((line, index) => {
      return (
        <span
          key={index}
          className={`profile__description-line profile__description-line--${index +
            1}`}
        >
          {line}
          <br />
        </span>
      );
    });
  }

  componentDidMount() {
    this.renderNewDescriptionLetter();
  }

  renderNewDescriptionLetter() {
    const newShowingProfileDescription = [
      ...this.state.showingProfileDescription
    ];

    newShowingProfileDescription[this.currentDescriptionLineTyping] =
      this.state.showingProfileDescription[this.currentDescriptionLineTyping] +
      this.profileDescription[this.currentDescriptionLineTyping][
        this.currentDescriptionCharacterTyping
      ];

    this.currentDescriptionCharacterTyping++;

    if (
      this.currentDescriptionCharacterTyping ===
      this.profileDescription[this.currentDescriptionLineTyping].length
    ) {
      this.currentDescriptionLineTyping++;
      this.currentDescriptionCharacterTyping = 0;
    }
    this.setState(
      {
        showingProfileDescription: [...newShowingProfileDescription]
      },
      () => {
        if (
          this.currentDescriptionLineTyping < this.profileDescription.length
        ) {
          setTimeout(() => {
            this.renderNewDescriptionLetter();
          }, 20);
        }
      }
    );
  }

  render() {
    return (
      <div className='profile'>
        <h1 className='profile__title'>
          <span className='overlapped-underline'>Emma Livingstone</span>
        </h1>
        <p className='profile__description'>{this.renderDescriptionLines()}</p>
        <div className='profile__skills'>
          <JavaScriptLogo className='profile__skill profile__skill--javascript' />
          <AngularLogo className='profile__skill profile__skill--angular' />
          <ReactLogo className='profile__skill profile__skill--react' />
          <TypeScriptLogo className='profile__skill profile__skill--typescript' />
          <ReduxLogo className='profile__skill profile__skill--redux' />
          <SassLogo className='profile__skill profile__skill--sass' />
        </div>
      </div>
    );
  }
}
